body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    /* font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}


/* bootstrap styles override */
.modal-dialog .modal-content {
  border-radius: 4px;
  padding: 10px 16px;
}

.modal-dialog .modal-content .modal-header {
  border:none;
}

.modal-dialog .modal-content .modal-body {
  overflow: auto;
}

.modal-dialog .modal-content .modal-header .modal-title h4 {
  font-size: 20px;
  line-height: 28px;
  color: #242424;
}

.modal-dialog .modal-content .modal-footer {
  margin: 20px 14px 0 14px;
  padding: 20px 0 10px 0px;
  justify-content: center;
}

@media (min-width: 768px) {
  .modal-dialog.modal-custom-size-90 {
    max-width: none !important;
    width: 90% !important;
    height: 90% !important;
  }
  .modal-dialog.modal-custom-size-90 .modal-content {
    height: 90% !important;
  }
}

button {
  box-shadow: none !important;
}

.btn {
  border-radius: 2px !important;
  padding: 8px 20px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;  
}

.btn.btn-primary {
    background: #1B9BB8 !important;
    border: 1px solid #1B9BB8 !important;
    color:#fff !important;
}

.btn.btn-secondary {
  border: 1px solid #616161 !important;
  background: none !important;
  color: #616161 !important;
}

.form-label {
  font-size: 12px !important;
  line-height: 16px !important;
  font-weight: 400 !important;
}

input.form-control {
  background: #F5F5F5 !important;
  border: none !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  border-radius: 3px !important;  
}

input.form-control:focus {
  box-shadow: none !important;
}

select:disabled:hover {
  cursor: not-allowed !important;
}

select.form-select {
  background-color: #F5F5F5 !important;
  border: none !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  border-radius: 3px !important;
}

select.form-select:focus {
  box-shadow: none !important;
}

/* END bootstrap styles override */

.ac-pushButton > div {
  white-space: normal !important;
}

.botActivityDecorator {
  display: flex;
}

.botActivityDecorator .botActivityDecorator__buttonBar .botActivityDecorator__button {
  border: none;
  background: none;
}

.buttons-like-dislike {
  display: flex;
}

.buttons-like-dislike .btn-like {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  padding: 5px 15px;
  background-color: #F5F5F5;
  outline: none !important;
}

.buttons-like-dislike .btn-dislike {
  display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid #ccc;
    border-radius: 0 4px 4px 0;
    padding: 5px 15px;
    margin-left: -1px;
    background-color: #F5F5F5;
    outline: none !important;
}

.txt-feedback {
  width: 100%;
  background-color: #F5F5F5;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  outline: none !important;
}

.splashScreen {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position:50% 50%;
  background-size: cover;
}